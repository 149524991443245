export default [
    {
        name: "OPPP"
    },
    {
        name: "步步高"
    },
    {
        name: "VIVO"
    },
    {
        name: "优必选"
    },
    {
        name: "华为"
    },
    {
        name: "apple"
    },
    {
        name: "小米"
    },
    {
        name: "糖猫"
    },
    {
        name: "小天才"
    },
    {
        name: "apple"
    },
    {
        name: "小米"
    },
    {
        name: "糖猫"
    },
    {
        name: "小天才"
    }
];
