<template>
	<div class="g-page">
		<Nav />

		<picture>
			<source
				media="(max-width: 1024px)"
				srcset="@/assets/images/mobile/container/about-us/banner.png"
			/>
			<source
				media="(min-width: 1025px)"
				srcset="@/assets/images/container/about-us/banner.png"
			/>
			<img
				class="banner"
				src="@/assets/images/container/about-us/banner.png"
			/>
		</picture>

		<AboutUs />

		<Footer />

		<BackToTop />
	</div>
</template>

<script>
import Nav from "@/components/nav.vue";
import AboutUs from "@/components/about-us.vue";
import Footer from "@/components/footer.vue";
import BackToTop from "@/components/back-to-top.vue";

export default {
	components: {
		Nav,
		AboutUs,
		Footer,
		BackToTop
	},

	data() {
		return {
			listIdxs: {
				brief: 0,
				business: 4,
				join: 5,
				news: 6
			}
		};
	},

	mounted() {}
};
</script>

<style lang="less" scoped>
.banner {
	width: 100%;
	margin-bottom: 80px;
}

@media (max-width: 1024px) {
	.banner {
		margin-bottom: calc(100vw / 1080 * 100);
	}
}
</style>
