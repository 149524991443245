<template>
    <div>
        <!-- 公司简介 -->
        <div class="container-item">
            <b-container>
                <picture>
                    <source
                        media="(max-width: 1024px)"
                        srcset="@/assets/images/mobile/container/about-us/title_1.png"
                    />
                    <source
                        media="(min-width: 1025px)"
                        srcset="@/assets/images/container/about-us/title_1.png"
                    />
                    <img
                        class="container-item__title"
                        src="@/assets/images/container/about-us/title_1.png"
                    />
                </picture>

                <div class="container-item__brief">
                    深圳市维体运动科技有限公司是由步步高领衔投资的一家互联网教育软件研发运营公司，公司成立于2018年，专注于线上教育，截至目前公司旗下产品累计用户已达数百万，增长迅速！
                </div>
                <div class="container-item__brief">
                    团队核心成员均来自步步高、西山居、腾讯、阿里等知名企业，拥有10年以上的内容、产品研发经验和丰富的线上教育经历，为了共同的目标走到一起，为广大用户带来全方位的教育娱乐体验。
                </div>

                <div class="container-item__pet">
                    <picture>
                        <source
                            media="(max-width: 1024px)"
                            srcset="@/assets/images/mobile/container/about-us/pic_1.png"
                        />
                        <source
                            media="(min-width: 1025px)"
                            srcset="@/assets/images/container/about-us/pic_1.png"
                        />
                        <img
                            src="@/assets/images/container/about-us/pic_1.png"
                        />
                    </picture>
                </div>
            </b-container>
        </div>

        <!-- 企业文化 -->
        <div class="container-item">
            <b-container>
                <picture>
                    <source
                        media="(max-width: 1024px)"
                        srcset="@/assets/images/mobile/container/about-us/title_2.png"
                    />
                    <source
                        media="(min-width: 1025px)"
                        srcset="@/assets/images/container/about-us/title_2.png"
                    />
                    <img
                        class="container-item__title"
                        src="@/assets/images/container/about-us/title_2.png"
                    />
                </picture>

                <div class="container-item__subtitle">
                    本分、诚信、团队、品质、消费者导向
                </div>

                <template v-if="!isMobile">
                    <div class="container-item__cultures">
                        <div
                            v-for="num in 4"
                            :key="num"
                            class="container-item__culture"
                        >
                            <img
                                :src="
                                    require(`@/assets/images/container/about-us/text_${num}.png`)
                                "
                            />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <img
                        class="container-item__culture"
                        src="@/assets/images/mobile/container/about-us/text.png"
                    />
                </template>
            </b-container>
        </div>

        <!-- 所获荣誉 -->
        <div class="container-item container-item--prize">
            <b-container>
                <picture>
                    <source
                        media="(max-width: 1024px)"
                        srcset="@/assets/images/mobile/container/about-us/title_3.png"
                    />
                    <source
                        media="(min-width: 1025px)"
                        srcset="@/assets/images/container/about-us/title_3.png"
                    />
                    <img
                        class="container-item__title"
                        src="@/assets/images/container/about-us/title_3.png"
                    />
                </picture>

                <b-row>
                    <b-col sm="12" xl="6">
                        <img
                            class="container-item__prize"
                            src="@/assets/images/container/about-us/prize_1.png"
                        />
                    </b-col>

                    <b-col sm="12" xl="6">
                        <img
                            class="container-item__prize"
                            src="@/assets/images/container/about-us/prize_2.png"
                        />
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- 合作伙伴 -->
        <div class="container-item">
            <picture>
                <source
                    media="(max-width: 1024px)"
                    srcset="@/assets/images/mobile/container/about-us/title_4.png"
                />
                <source
                    media="(min-width: 1025px)"
                    srcset="@/assets/images/container/about-us/title_4.png"
                />
                <img
                    class="container-item__title"
                    src="@/assets/images/container/about-us/title_4.png"
                />
            </picture>

            <div class="container-item__partner">
                <b-container>
                    <div class="container-item__partners">
                        <div
                            class="container-item__partner-logo"
                            v-for="(item, idx) in partners"
                            :key="idx"
                        >
                            <picture>
                                <source
                                    media="(max-width: 1024px)"
                                    :srcset="
                                        require(`@/assets/images/mobile/container/about-us/logo/${idx}.png`)
                                    "
                                />
                                <source
                                    media="(min-width: 1025px)"
                                    :srcset="
                                        require(`@/assets/images/container/about-us/logo/${idx}.png`)
                                    "
                                />
                                <img
                                    :src="
                                        require(`@/assets/images/container/about-us/logo/${idx}.png`)
                                    "
                                />
                            </picture>
                            <!-- <div>{{ item.name }}</div> -->
                        </div>
                    </div>
                </b-container>
            </div>
        </div>

        <!-- 商务合作 -->
        <div class="container-item">
            <picture>
                <source
                    media="(max-width: 1024px)"
                    srcset="@/assets/images/mobile/container/about-us/title_5.png"
                />
                <source
                    media="(min-width: 1025px)"
                    srcset="@/assets/images/container/about-us/title_5.png"
                />
                <img
                    class="container-item__title"
                    src="@/assets/images/container/about-us/title_5.png"
                />
            </picture>

            <div class="business">
                <img
                    class="business-bg"
                    src="@/assets/images/container/about-us/map_bg.png"
                />

                <b-container>
                    <img
                        class="container-item__header"
                        src="@/assets/images/container/about-us/map_header.png"
                        v-if="!isMobile"
                    />

                    <div class="business-container">
                        <b-row>
                            <b-col sm="12" xl="6">
                                <div class="business-center">
                                    <div class="business-map">
                                        <baidu-map
                                            :center="{
                                                lng: 113.950875,
                                                lat: 22.539847
                                            }"
                                            :zoom="16"
                                            class="business-map__view"
                                        >
                                            <bm-marker
                                                :position="{
                                                    lng: 113.950875,
                                                    lat: 22.539847
                                                }"
                                                :dragging="true"
                                                :icon="{
                                                    url: require('@/assets/images/container/about-us/position.png'),
                                                    size: {
                                                        width: 38,
                                                        height: 55
                                                    }
                                                }"
                                            ></bm-marker>
                                        </baidu-map>
                                    </div>
                                </div>
                            </b-col>
                            <b-col sm="12" xl="6">
                                <div class="business-center">
                                    <div class="business-item">
                                        <div>
                                            <img
                                                class="business-item__icon"
                                                src="@/assets/images/container/about-us/map_wx.png"
                                            />
                                        </div>
                                        <div class="business-item__msg">
                                            客服微信：<a href="https://work.weixin.qq.com/kfid/kfcda4f0889a37699f1">点击联系</a>
                                        </div>
                                    </div>

                                    <div class="business-item">
                                        <div>
                                            <img
                                                class="business-item__icon"
                                                src="@/assets/images/container/about-us/map_email.png"
                                            />
                                        </div>
                                        <div class="business-item__msg">
                                            商务邮箱：business@qimengkeji.net
                                        </div>
                                    </div>
                                    <div class="business-item">
                                        <div>
                                            <img
                                                class="business-item__icon"
                                                src="@/assets/images/container/about-us/map_address.png"
                                            />
                                        </div>
                                        <div class="business-item__msg">
                                            公司地址：深圳市南山区科技园高新南7道20号虚拟大学园区R2-B栋3楼
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>

                    <img
                        v-if="!isMobile"
                        class="container-item__footer"
                        src="@/assets/images/container/about-us/news_bg_1.png"
                    />
                </b-container>
            </div>
        </div>

        <!-- 加入我们 -->
        <div v-if="hasJoin" class="container-item">
            <b-container>
                <picture>
                    <source
                        media="(max-width: 1024px)"
                        srcset="@/assets/images/mobile/container/about-us/title_6.png"
                    />
                    <source
                        media="(min-width: 1025px)"
                        srcset="@/assets/images/container/about-us/title_6.png"
                    />
                    <img
                        class="container-item__title"
                        src="@/assets/images/container/about-us/title_6.png"
                    />
                </picture>

                <div
                    @click="onJoinClick"
                    class="join"
                    v-if="!isJoinDetailOpened"
                >
                    <div
                        v-for="(item, idx) in joins"
                        :key="idx"
                        class="join-item"
                    >
                        <div class="join-item__title">
                            {{ item.name }}
                        </div>

                        <picture>
                            <source
                                media="(max-width: 1024px)"
                                :srcset="
                                    require(`@/assets/images/mobile/container/about-us/join_${item.id}.png`)
                                "
                            />
                            <source
                                media="(min-width: 1025px)"
                                :srcset="
                                    require(`@/assets/images/container/about-us/join_${item.id}.png`)
                                "
                            />
                            <img
                                class="join-item__icon"
                                :src="
                                    require(`@/assets/images/container/about-us/join_${item.id}.png`)
                                "
                            />
                        </picture>
                    </div>
                </div>

                <div @mouseup="onJoinMouseUp" v-else class="join-detail">
                    <div class="join-detail__title">
                        前端工程师
                        <span>招聘2人</span>
                    </div>

                    <div class="join-detail__content">
                        <div class="join-detail__left">
                            <div class="join-detail__subtitle">
                                任职要求:
                            </div>
                            1. 计算机相关专业,本科及以上学历； <br />
                            2. 1年以上工作经验；<br />
                            3. 拥有良好的设计意识； <br />
                            4. 精通css3/html5，熟悉jquery； <br />
                            5. 能力强者1、2条件不限；
                        </div>

                        <div class="join-detail__right">
                            <div class="join-detail__subtitle">
                                岗位职责:
                            </div>
                            1. 负责日常网站设计、开发、维护； <br />
                            2. 负责活动H5页面设计、开发；<br />
                            3. 负责后台管理界面的设计、开发； <br />
                            4. 进行软件代码的维护和改进工作； <br />
                        </div>
                    </div>

                    <div class="join-detail__footer">
                        <span class="join-detail__subtitle"
                            >有意者请将简历投递至：</span
                        >16675389196@163.com
                    </div>
                </div>
            </b-container>
        </div>

        <!-- 最新动态 -->
        <div v-if="hasNews" class="container-item">
            <b-container>
                <picture>
                    <source
                        media="(max-width: 1024px)"
                        srcset="@/assets/images/mobile/container/about-us/title_7.png"
                    />
                    <source
                        media="(min-width: 1025px)"
                        srcset="@/assets/images/container/about-us/title_7.png"
                    />
                    <img
                        class="container-item__title"
                        src="@/assets/images/container/about-us/title_7.png"
                    />
                </picture>

                <img
                    class="container-item__header"
                    src="@/assets/images/container/about-us/news_bg_0.png"
                />

                <div class="news">
                    <div
                        v-for="(item, idx) in news"
                        :key="idx"
                        class="news-item"
                    >
                        <div class="news-item__content">
                            {{ item.content }}

                            <img
                                v-if="item.url"
                                class="news-item__btn"
                                src="@/assets/images/container/about-us/news_btn.png"
                                @click="onNewsClick(item.url)"
                            />
                        </div>
                        <div class="news-item__date">
                            {{ item.date }}
                        </div>
                    </div>
                </div>

                <img
                    class="container-item__footer"
                    src="@/assets/images/container/about-us/news_bg_1.png"
                />
            </b-container>
        </div>
    </div>
</template>

<script>
import partners from "@/config/partners";
import joins from "@/config/joins";
import news from "@/config/news";
import navs from "@/config/navs";

export default {
    data() {
        return {
            partners,
            joins,
            news,
            isJoinDetailOpened: 0,
            isMobile: this.$userAgent.isMobile
        };
    },

    methods: {
        onJoinClick() {
            this.isJoinDetailOpened = !this.isJoinDetailOpened;
        },

        onJoinMouseUp() {
            const selection = window.getSelection().toString();

            this.isJoinDetailOpened = !!selection;
        },

        onNewsClick(url) {
            window.open(url);
        }
    },

    computed: {
        hasNews() {
            let hasNews = false;

            navs[0].list.forEach(item => {
                if (item.url === "news") {
                    hasNews = true;
                }
            });

            return hasNews;
        },
        hasJoin() {
            let hasJoin = false;

            navs[0].list.forEach(item => {
                if (item.url === "join") {
                    hasJoin = true;
                }
            });

            return hasJoin;
        }
    },

    mounted() {}
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";

.get-container-max-width(@styles);

@styles: {
    .container {
        &-item {
            margin-bottom: 150px;
            text-align: center;

            .container {
                width: calc(100vw / 1920 * 960);
                padding: 0;
            }

            &:nth-child(1) {
                margin-bottom: 120px;
            }

            &__title {
                margin-bottom: 20px;
                width: calc(100vw / 1920 * 272);
            }

            &__brief {
                text-align: justify;
                text-indent: 2em;
                color: #6a6a6a;
                font-size: calc(100vw / 1920 * 20);
            }

            &__pet {
                width: 100%;
                margin-top: 20px;

                img {
                    width: 100%;
                }
            }

            &__subtitle {
                font-size: calc(100vw / 1920 * 30);
                color: #6a6a6a;
                margin-bottom: calc(100vw / 1920 * 25);
            }

            &__cultures {
                display: flex;
                justify-content: center;
            }

            &__culture {
                width: calc(100vw / 1920 * 225);

                img {
                    width: 100%;
                }

                + .container-item__culture {
                    margin-left: calc(100vw / 1920 * 22);
                }
            }

            &__prize {
                width: calc(100vw / 1920 * 465);
            }

            &--prize {
                .col-md-6 {
                    text-align: right;
                    padding: 0;
                    padding-right: calc(100vw / 1920 * 16);

                    + .col-md-6 {
                        text-align: left;
                        padding: 0;
                        padding-left: calc(100vw / 1920 * 16);
                    }
                }
            }

            &__partners {
                display: flex;
                flex-wrap: wrap;
                padding: 0 calc(100vw / 1920 * 30);
            }

            &__partner {
                background-image: url("../assets/images/container/about-us/partner_bg.png");
                background-size: auto 100%;
                background-position: 0 100%;
                padding: calc(100vw / 1920 * 35) 0 0;
                font-size: calc(100vw / 1920 * 24);
                color: #383838;

                .container {
                    padding: 0;
                }

                &-logo {
                    margin-bottom: calc(100vw / 1920 * 45);
                    margin-right: calc(100vw / 1920 * 74);

                    img {
                        width: calc(100vw / 1920 * 120);
                    }

                    &:nth-child(5n) {
                        margin-right: 0;
                    }
                }
            }

            &__header {
                width: 100%;
                vertical-align: bottom;
            }

            &__footer {
                width: 100%;
                vertical-align: top;
            }
        }
    }

    .join {
        display: flex;
        padding: calc(100vw / 1920 * 50) 0 0;
        width: calc(100vw / 1920 * 960);
        height: calc(100vw / 1920 * 347);
        background-image: url("../assets/images/container/about-us/join_bg_0.png");
        background-size: 100% 100%;

        &-item {
            width: calc(100vw / 1920 * 240);

            &__title {
                color: #383838;
                margin-bottom: calc(100vw / 1920 * 25);
                font-size: calc(100vw / 1920 * 24);
                line-height: 1;
            }

            &__icon {
                width: 100%;
            }

            + .join-item {
                // margin-left: calc(100vw / 1920 * 40);
            }
        }

        &-detail {
            width: calc(100vw / 1920 * 960);
            height: auto;
            background-image: url("../assets/images/container/about-us/join_bg_1.png");
            background-size: 100% 100%;

            padding-top: calc(100vw / 1920 * 45);
            padding-bottom: calc(100vw / 1920 * 60);
            text-align: left;
            font-size: calc(100vw / 1920 * 20);

            box-sizing: border-box;

            &__title {
                font-size: calc(100vw / 1920 * 28.8);
                color: #383838;
                margin-bottom: calc(100vw / 1920 * 35);
                padding-left: calc(100vw / 1920 * 70);
                line-height: 1;

                span {
                    color: #495e8f;
                    font-size: calc(100vw / 1920 * 19.2);
                }
            }

            &__subtitle {
                color: #58cde4;
                line-height: 1.25;
                position: relative;

                &::before {
                    position: absolute;
                    left: calc(100vw / 1920 * (-30));
                    top: 50%;
                    background: #4cc7df;
                    width: calc(100vw / 1920 * 9.59);
                    height: calc(100vw / 1920 * 9.59);
                    transform: translateY(-50%);
                    content: " ";
                    border-radius: 50%;
                }
            }

            &__content {
                color: #8f8f8f;
                display: flex;
                padding-bottom: calc(100vw / 1920 * 24);
                line-height: 1.25;
            }

            &__left {
                padding-left: calc(100vw / 1920 * 100);
                min-width: 50%;
            }

            &__right {
                padding-left: calc(100vw / 1920 * 100);
                min-width: 50%;
            }

            &__footer {
                color: #495e8f;
                padding-left: calc(100vw / 1920 * 100);
            }
        }
    }

    .news {
        background: #fff;
        padding: 0 calc(100vw / 1920 * 30);

        .container {
        }

        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            + .news-item {
                margin-top: calc(100vw / 1920 * 20);
            }

            &__content {
                color: #495e8f;
                font-size: calc(100vw / 1920 * 20);
                display: flex;
                align-items: center;
            }

            &__btn {
                margin-left: calc(100vw / 1920 * 10);
                cursor: pointer;
            }

            &__date {
                color: #858585;
                font-size: calc(100vw / 1920 * 20);
            }
        }
    }

    .business {
        position: relative;
        overflow: hidden;
        padding-bottom: calc(100vw / 1920 * 38);

        .container {
            padding: 0;

            .row {
                margin: 0;
            }
        }

        .col-md-6 {
            + .col-md-6 {
                .business-center {
                    // margin-left: calc(100vw / 1920 * 40);
                }
            }
        }

        &-container {
            background: #fff;
            color: #495e8f;
            font-size: calc(100vw / 1920 * 20);

            .col-sm-12 {
                padding: 0;
            }
        }

        &-bg {
            width: 100vw;
            height: calc(464 / 1920 * 100vw);
            position: absolute;
            left: 50%;
            top: calc(100vw / 1920 * 58);
            z-index: -1;
            transform: translateX(-50%);
        }

        &-item {
            display: flex;
            text-align: left;
            margin-left: calc(100vw / 1920 * 40);
            margin-top: calc(100vw / 1920 * 86);

            &__icon {
                margin-right: calc(100vw / 1920 * 10);
                width: calc(100vw / 1920 * 32);
            }

            &__msg {
                word-break: break-all;
                width: calc(100vw / 1920 * 340);
            }

            + .business-item {
                margin-top: calc(100vw / 1920 * 20);
            }
        }

        &-map {
            width: calc(100vw / 1920 * 439);
            height: calc(100vw / 1920 * 269);
            border: 4px solid #e8feff;
            overflow: hidden;
            border-radius: calc(100vw / 1920 * 20);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: calc(100vw / 1920 * 50);
            margin-top: calc(100vw / 1920 * 30);

            &__view {
                width: 100%;
                height: 100%;
            }
        }
    }
};

@media (max-width: 1024px) {
    .container {
        padding: 0 !important;

        &-item {
            margin-bottom: calc(100vw / 1080 * 150);
            text-align: center;

            .container {
                width: auto;
            }

            &__title {
                margin-bottom: calc(100vw / 1080 * 30);
                width: calc(100vw / 1080 * 510);
            }

            &__brief {
                text-align: justify;
                text-indent: 2em;
                color: #6a6a6a;
                font-size: calc(100vw / 1080 * 48);
                padding: 0 calc(100vw / 1080 * 50);
            }

            &__pet {
                margin-top: calc(100vw / 1080 * 60);
                overflow-x: scroll;

                img {
                    width: calc(100vw / 1080 * 1200 + 30px);
                }
            }

            &__subtitle {
                font-size: calc(100vw / 1080 * 48);
                color: #6a6a6a;
                margin-bottom: calc(100vw / 1080 * 50);
            }

            &__culture {
                width: calc(100vw / 1080 * 846);
            }

            &__prize {
                width: calc(100vw / 1080 * 836);
                margin-bottom: calc(100vw / 1080 * 50);
            }

            &--prize {
                margin-bottom: calc(100vw / 1080 * 100);

                .col-sm-12 {
                    padding: 0;
                    text-align: center !important;
                }
            }

            &__partners {
                display: flex;
                flex-wrap: wrap;
            }

            &__partner {
                background-image: url("../assets/images/container/about-us/partner_bg.png");
                background-size: auto 100%;
                background-position: 0 100%;
                padding: calc(100vw / 1080 * 95) 0 calc(100vw / 1080 * 60);
                font-size: calc(100vw / 1080 * 24);
                color: #383838;

                &-logo {
                    margin-bottom: calc(100vw / 1080 * 80);
                    margin-right: 0;
                    width: 33.33%;

                    img {
                        width: calc(100vw / 1080 * 210);
                    }

                    &:nth-child(5) {
                        margin-right: 0;
                    }
                }
            }

            &__header {
                width: 100%;
                vertical-align: bottom;
            }

            &__footer {
                width: 100%;
                vertical-align: top;
            }
        }
    }

    .business {
        position: relative;
        overflow: hidden;
        padding-bottom: calc(100vw / 1920 * 38);

        background-image: url("../assets/images/mobile/container/about-us/map_1.png");
        background-size: 100% 100%;
        background-position: 0 0;
        width: 100vw;
        height: calc(100vw / 1080 * 1412);

        .col-sm-12 {
            padding: 0 !important;
        }

        &-container {
            background: none;
            color: #495e8f;
            font-size: calc(100vw / 1080 * 24);
        }

        &-bg {
            width: 100vw;
            height: calc(580 / 1920 * 100vw);
            position: absolute;
            left: 50%;
            top: calc(100vw / 1080 * 58);
            z-index: -1;
            transform: translateX(-50%);
        }

        &-item {
            display: flex;
            text-align: left;
            padding-left: calc(100vw / 1080 * 130);
            margin-top: 0;

            &__icon {
                margin-right: calc(100vw / 1080 * 19);
                width: calc(100vw / 1080 * 61);
            }

            &__msg {
                width: calc(100vw / 1080 * 620);
                font-size: calc(100vw / 1080 * 36);
                word-break: break-all;
                text-align: justify;
            }

            + .business-item {
                margin-top: calc(100vw / 1080 * 30);
            }
        }

        &-map {
            width: calc(100vw / 1080 * 831);
            height: calc(100vw / 1080 * 500);
            border: calc(100vw / 1080 * 15) solid #e8feff;
            overflow: hidden;
            border-radius: calc(100vw / 1080 * 30);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: calc(100vw / 1080 * 245) auto calc(100vw / 1080 * 65);

            &__view {
                width: 100%;
                height: 100%;
            }
        }
    }

    .news {
        background: #fff;
        padding: calc(100vw / 1080 * 40) calc(100vw / 1080 * 35)
            calc(100vw / 1080 * 50);

        &-item {
            display: block;

            + .news-item {
                margin-top: 20px;
            }

            &__content {
                color: #495e8f;
                font-size: calc(100vw / 1080 * 36);
                display: flex;
                align-items: center;
                text-align: left;
            }

            &__btn {
                margin-left: calc(100vw / 1080 * 12);
                width: calc(100vw / 1080 * 57);
            }

            &__date {
                color: #858585;
                font-size: calc(100vw / 1080 * 36);
                text-align: right;
                margin-top: calc(100vw / 1080 * 20);
            }
        }
    }

    .join {
        display: flex;
        padding: calc(100vw / 1080 * 70) 0 calc(100vw / 1080 * 54);
        width: calc(100vw / 1080 * 1000);
        height: auto;
        background-image: url("../assets/images/mobile/container/about-us/join_bg_0.png");
        background-size: 100% 100%;
        margin: 0 auto;
        flex-wrap: wrap;

        &-item {
            width: 33.33%;

            &__title {
                color: #383838;
                margin-bottom: calc(100vw / 1080 * 10);
                font-size: calc(100vw / 1080 * 39);
                line-height: 1;
            }

            &__icon {
                width: 100%;
            }

            + .join-item {
                margin-left: 0;
            }
        }

        &-detail {
            width: calc(100vw / 1080 * 1000);
            height: auto;
            background-image: url("../assets/images/container/about-us/join_bg_1.png");
            background-size: 100% 100%;

            padding-top: calc(100vw / 1080 * 45);
            padding-bottom: calc(100vw / 1080 * 40);
            text-align: left;
            font-size: calc(100vw / 1080 * 30);
            margin: 0 auto;

            &__title {
                font-size: calc(100vw / 1080 * 48);
                color: #383838;
                margin-bottom: calc(100vw / 1080 * 35);
                padding-left: calc(100vw / 1080 * 70);
                line-height: 1;

                span {
                    color: #495e8f;
                    font-size: calc(100vw / 1080 * 30);
                }
            }

            &__subtitle {
                color: #58cde4;
                line-height: 1.25;
                position: relative;

                &::before {
                    position: absolute;
                    left: calc(100vw / 1080 * (-20));
                    top: 50%;
                    background: #4cc7df;
                    width: calc(100vw / 1080 * 20);
                    height: calc(100vw / 1080 * 20);
                    transform: translateY(-50%);
                    content: " ";
                    border-radius: 50%;
                }
            }

            &__content {
                color: #8f8f8f;
                display: flex;
                margin-bottom: calc(100vw / 1080 * 29);
                line-height: 1.25;
                flex-wrap: wrap;
            }

            &__left {
                width: 100%;
                margin-left: calc(100vw / 1080 * 100);
                margin-bottom: calc(100vw / 1080 * 29);
            }

            &__right {
                width: 100%;
                margin-left: calc(100vw / 1080 * 110);
            }

            &__footer {
                color: #495e8f;
                padding-left: calc(100vw / 1080 * 110);
            }
        }
    }
}
</style>
