export default [
    {
        id: 0,
        name: "程序工程师"
    },
    {
        id: 2,
        name: "美术设计师"
    },
    {
        id: 3,
        name: "运营策划师"
    },
    {
        id: 1,
        name: "产品测试"
    }
];
