export default [
    {
        content: "兜兜龙新版本上线啦！快去看看吧!dadadaadadadadd!  ",
        date: "2020.01.17",
        url: ""
    },
    {
        content: "兜兜龙新版本上线啦！快去看看吧!dadadaadadadadd!  ",
        date: "2020.01.17",
        url: ""
    },
    {
        content: "百度",
        date: "2020.01.17",
        url: "https://www.baidu.com/"
    }
];
